<template>
  <div class="box">
    <!-- 矩阵问题 -->
    <GlobalInfoBar
      title="矩阵管理"
      content="添加矩阵、管理矩阵报价回收商以及矩阵设置"
    />
    <!-- 查询 -->
    <GlobalChunk icon="search" title="筛选查询">
      <!-- 筛选 -->
      <template v-slot:filter>
        <GlobalForm
          :form-item-list="formItemList"
          round
          size="mini"
          :inline="true"
          :init-data="initData"
          :needBtnLoading="true"
          @handleConfirm="handleConfirm"
          confirm-btn-name="查询"
        >
          <template v-slot:AddSlot>
            <el-button type="primary" icon="el-icon-plus" @click="createClick"
              >新建矩阵</el-button
            >
          </template>
        </GlobalForm>
      </template>
      <!--  表+头    -->
      <template v-slot:default>
        <!-- 头 -->
        <div class="table-header">
          <div class="header-icon">
            <img src="@/assets/images/icon_lb@2x.png" alt="" />
            <h2>矩阵列表</h2>
          </div>
          <el-button
            style="margin-top: -10px"
            type="primary"
            @click="addStore"
            :disabled="addStoreBtn"
            >批量添加回收商</el-button
          >
        </div>
        <!-- 表 -->
        <el-table
          ref="listData"
          border
          size="small"
          :header-cell-style="{ background: '#E4ECFD', padding: 0 }"
          v-loading="loading"
          :data="listData"
          @selection-change="handleTableSelect"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            v-for="item in listColumns"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
          >
            <template v-slot="{ row }">
              <!-- 操作-->
              <div v-if="item.prop === 'action'">
                <div v-if="row.isOut === '是'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="外部渠道商无法设置矩阵"
                    placement="top-start"
                  >
                    <div class="f-line">矩阵设置</div>
                  </el-tooltip>
                </div>
                <!-- <div class="fc_line" v-if="row.isOut === '否'" @click="settingClick(row)"> -->
                <div
                  class="fc_line"
                  v-if="row.isOut === '否'"
                  @click="$refs['settingDia'].open(row)"
                >
                  矩阵设置
                </div>
                <div v-if="row.isOut === '--'">--</div>
              </div>

              <!-- 是否外部渠道-->
              <div v-else-if="item.prop === 'isOut'">
                <!-- 是否外部渠道商 -->
                <div v-if="row[item.prop] === '--'">--</div>
                <div v-if="row[item.prop] === '是'">
                  <span>是</span>
                  <p class="fc">{{ row.channelName }}</p>
                </div>
                <div v-if="row[item.prop] === '否'">
                  <span>否</span>
                </div>
              </div>
              <!-- 外部商户号/渠道-->
              <div v-else-if="item.prop === 'Outchannel'">
                <!-- 是否外部渠道商 -->
                <div v-if="(row.outMerchantId === '--'&&row.chxChannelCode=== '--')||(row.channelCode!=='xm'&&row.channelCode !=='ygx')">--</div>
                <div v-else>
                  <div >商户号：{{ row.outMerchantId||"--" }}</div>
                  <div v-if="row.chxChannelCode=== '--'" style="color:red">未绑定渠道ID</div>
                  <div v-else style="display: flex;
                  align-items: center;">
                    <el-tag size="small" type="warning" v-if="row.isDefault">默认</el-tag>
                    <span>渠道ID：{{ row.chxChannelCode}}</span>
                  </div>
                </div>
              </div>
              <!-- 是否厂家订单-->
              <div v-else-if="item.prop === 'isManufacturers'">
                <!-- 是否外部渠道商 -->
                <div v-if="row[item.prop]">是</div>
                <div v-else>否</div>
                <template v-if="row.isOut === '是'">
                  <div
                    v-if="row[item.prop]"
                    class="fc_line"
                    @click="$refs['tagDia'].open(row, '01')"
                  >
                    取消标记厂家订单
                  </div>
                  <div
                    v-else
                    class="fc_line danger"
                    @click="$refs['tagDia'].open(row, '00')"
                  >
                    标记厂家订单
                  </div>
                </template>
              </div>

              <!-- 报价回收商 -->
              <div v-else-if="item.prop === 'merchantNum'">
                <div class="fc_line" @click="recoveryNum(row)">
                  {{ row[item.prop] }}
                </div>
                <div class="fc_line" @click="addRecovery(row)">添加</div>
              </div>

              <!-- 包含门店商 -->
              <div v-else-if="item.prop === 'companyNum'">
                <div v-if="row.isOut === '是'">--</div>
                <a
                  v-else
                  class="fc_line"
                  @click="storeListHref(row)"
                  target="_blank"
                  :href="`/StoreShop/index?matrixId=${row.id}&matrixName=${row.matrixName}`"
                >
                  {{ row[item.prop] }}
                </a>
              </div>
              <!-- 矩阵回收商 -->
              <div v-else-if="item.prop === 'merchantName'">
                <div v-if="row.isOut === '是'">外部渠道无需设置</div>
                <div
                  v-else
                  class="fc_line"
                  @click="
                    openAction(`/merchant/list?merchantName=${row[item.prop]}`)
                  "
                >
                  {{ row[item.prop] }}
                </div>
              </div>

              <!-- 矩阵门店商户 -->
              <div v-else-if="item.prop === 'companyName'" class="fc_line">
                <p
                  v-if="row[item.prop] !== '--'"
                  @click="
                    openAction(`/StoreShop/index?companyName=${row[item.prop]}`)
                  "
                >
                  {{ row[item.prop] }}
                </p>
                <p v-else>--</p>
              </div>

              <!-- 默认渲染 -->
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :meta="meta"
          @sizeChange="
            (v) => {
              meta = { ...meta, pageSize: v };
              params = { ...params, pageSize: v };
            }
          "
          @pageChange="
            (v) => {
              meta = { ...meta, pageNum: v };
              params = { ...params, pageNum: v };
            }
          "
        />
      </template>
    </GlobalChunk>
    <!-- 矩阵新建弹窗 -->
    <el-dialog
      title="新建矩阵"
      :visible.sync="isCreateShow"
      width="40%"
      :before-close="() => (isCreateShow = false)"
    >
      <h2 class="tip">说明：繁星自有矩阵添加后需要配置报价回收商才可使用</h2>
      <el-form
        class="create-form"
        :model="createMatrixForm"
        :rules="createMatrixRules"
        ref="createMatrix"
        label-width="140px"
        label-position="left"
      >
        <el-form-item
          :label="item.label"
          :prop="item.key"
          v-for="(item, index) in createMatrixFormList"
          :key="index"
          v-show="item.isShow"
        >
          <!-- 输入，需要结合type判断 -->
          <el-input
            clearable
            :disabled="item.disabled"
            v-model="createMatrixForm[item.key]"
            v-if="item.type === 'input'"
            :placeholder="item.placeholder"
            :maxlength="item.key === 'matrixName' ? 15 : null"
          ></el-input>
          <!-- 选择 -->
          <el-radio-group
            clearable
            v-model="createMatrixForm[item.key]"
            v-if="item.type === 'radio'"
          >
            <el-radio
              v-for="(v, index) in item.options"
              :key="index"
              :label="v.value"
              >{{ v.label }}</el-radio
            >
          </el-radio-group>

          <!-- 下拉 -->
          <el-select
            v-model="createMatrixForm[item.key]"
            v-if="item.type === 'select'"
            filterable
            clearable
            :placeholder="item.placeholder"
          >
            <el-option
              v-for="(v, index) in item.options"
              :key="index"
              :label="v.label"
              :value="v.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isCreateShow = false">取 消</el-button>
        <el-button type="primary" @click="createSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 新建矩阵谷歌验证码 -->
    <DialogAction
      @config="createMatrixSubmit"
      @codeChange="(v) => (createMatrixCodeProp.code = v)"
      @close="createMatrixCodeProp.show = false"
      v-bind="createMatrixCodeProp"
    />
    <!-- 厂家标记 -->
    <TagDia ref="tagDia" @confirm="getList()" />
    <!-- 矩阵时间设置 -->
    <SettingDia ref="settingDia" @confirm="getList()" />
    <el-dialog
      title="矩阵设置"
      :visible.sync="isSetting"
      width="30%"
      :before-close="() => (isSetting = false)"
    >
      <p class="tip" style="margin-bottom: 10px; margin-left: 20px">
        说明：设置后，按照设置取价时间显示在员工确认交易页面，并进行定时执行
      </p>
      <el-form
        :model="settingForm"
        ref="settingForm"
        class="time-input"
        label-position="left"
        label-width="110px"
      >
        <el-form-item
          label="矩阵取价时间"
          prop="priceTime"
          :rules="[
            { required: true, message: '请输入取价时间', trigger: 'change' },
            { validator: timeValidator, trigger: 'blur' },
          ]"
        >
          <el-input
            style="width: 70%"
            clearable
            @input="priceInput"
            v-model="settingForm.priceTime"
            placeholder="请输入取价时间，单位秒"
          />
          <span style="margin-left: 20px">秒</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isSetting = false">取 消</el-button>
        <el-button type="primary" @click="settingSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加回收商 -->
    <el-dialog
      title="添加回收商"
      v-if="isAddRecovery"
      :visible.sync="isAddRecovery"
      width="550px"
      :before-close="() => (isAddRecovery = false)"
    >
      <el-form
        :model="addRecoveryForm"
        ref="recoveryForm"
        label-position="left"
        label-width="140px"
      >
        <p v-if="moreChooseStore">
          已选择<span style="color: #0981ff; cursor: pointer"
            >{{ tableChooseLength }}个</span
          >矩阵，可批量添加合作商家
        </p>
        <div @click="addRecoveryClick">
          <el-form-item
            label="选择报价回收商"
            prop="merchantName"
            :rules="[
              { required: true, message: '请选择回收商', trigger: 'change' },
            ]"
          >
            <el-input
              style="width: 70%"
              clearable
              readonly
              suffix-icon="el-icon-arrow-right"
              @input="priceInput"
              v-model="addRecoveryForm.merchantName"
              placeholder="请选择回收商"
            />
          </el-form-item>
        </div>
        <div class="add-recover-info">
          <p>创建人：{{ addRecoveryForm.contactName || "--" }}</p>
          <p>联系电话：{{ addRecoveryForm.contactPhone || "--" }}</p>
        </div>
        <el-form-item
          label="选择分转仓"
          prop="warehouseId"
          v-if="
            (!moreChooseStore && currentData.isOut == '否') || moreChooseStore
          "
          :rules="[
            { required: true, message: '请选择仓库', trigger: 'change' },
          ]"
          style="margin-bottom: 0px; margin-top: 20px"
        >
          <el-select
            clearable
            size="small"
            v-model="addRecoveryForm.warehouseId"
            placeholder="请选择仓库"
            filterable
          >
            <el-option
              v-for="item in entrepotList"
              :key="item.id"
              :label="item.warehouseName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="手续費承担方"
          required
          style="margin-bottom: 0px; margin-top: 20px"
        >
          <el-radio-group :value="0">
            <el-radio
              v-for="(item, index) in ['回收商承担', '门店商承担']"
              :key="index"
              :label="index"
              :disabled="index === 1"
              >{{ item }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <p class="tip" style="margin-left: 20px">
          说明：矩阵报价默认回收商承担手续费
        </p>
        <el-form-item
          v-if="!moreChooseStore"
          label="动态口令"
          size="medium"
          label-width="100px"
          prop="command"
          :rules="[
            { required: true, message: '请输入动态口令', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="addRecoveryForm.command"
            placeholder="请输入谷歌动态验证码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isAddRecovery = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addRecoverySubmit"
          v-loading="addRecoveryForm.loading"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加回收商列表 -->
    <DialogTableSearch
      v-bind="addRecoveryProp"
      width="800px"
      @close="
        addRecoveryProp.show = false;
        addRecoveryProp.isResetForm = false;
      "
      @sizeChange="
        (v) => {
          addRecoveryProp = { ...addRecoveryProp, ...v };
          getRecoveryList();
        }
      "
      @pageChange="
        (v) => {
          addRecoveryProp = { ...addRecoveryProp, ...v };
          getRecoveryList();
        }
      "
      @handleConfirm="(v) => addRecoverySearch(v)"
      @config="addRecoveryConfig"
    >
      <!-- 表插槽 -->
      <template v-slot="{ row, prop }">
        <div v-if="prop !== 'select'">
          {{ row[prop] }}
        </div>
        <div v-else>
          <el-checkbox
            :value="selectRecoveryData.merchantId === row.merchantId"
            @change="addRecoveryChange(row)"
          ></el-checkbox>
        </div>
      </template>
    </DialogTableSearch>

    <!-- 矩陣报价回收商列表 -->
    <DialogTableSearch
      v-bind="recoveryNumProps"
      :isTabSelection="true"
      listKey="companyId"
      :isAction="true"
      :allSelectData="[]"
      @handleSelectionChange="merchantSelectionChange"
      @close="
        recoveryNumProps.show = false;
        recoveryNumProps.isResetForm = false;
      "
      @sizeChange="
        (v) => {
          recoveryNumProps = { ...recoveryNumProps, ...v };
          getRecoveryNumList();
        }
      "
      @pageChange="
        (v) => {
          recoveryNumProps = { ...recoveryNumProps, ...v };
          getRecoveryNumList();
        }
      "
      @handleConfirm="(v) => recoveryNumSearch(v)"
    >
      <template v-slot:action>
        <el-button
        v-if="currentData.isOut !== '是'"
          @click="allTrunon"
          size="small"
          :disabled="merchantselectData.length === 0"
          type="warning"
          >批量开启显示KA名称</el-button
        >
      </template>
      <!-- 表插槽 -->
      <template v-slot="{ row, prop }">
        <!-- 回收商名称 -->
        <div v-if="prop === 'merchantName'" class="fc_line">
          {{ row[prop] }}
        </div>
        <!-- 分转仓 -->
        <div v-else-if="prop === 'warehouseName'" class="fc_line">
          <el-select
            size="small"
            :disabled="row.isupdata"
            v-model="row.warehouseId"
            placeholder="请选择仓库"
            filterable
          >
            <el-option
              v-for="item in entrepotList"
              :key="item.id"
              :label="item.warehouseName"
              :value="item.id"
            ></el-option>
          </el-select>
          <div v-if="currentData.isOut == '否'">
            <div
              v-if="row.isupdata"
              @click="amendWarsh(row)"
              style="color: #f80000; text-decoration: underline"
            >
              修改
            </div>
            <div v-else @click="saveWarsh(row)">保存</div>
          </div>
        </div>
        <!-- 合作门店 -->
        <div v-else-if="prop === 'configStores'">
          <!-- <div class="fc_line" @click="storeList(row,'01')">{{ row[prop] }}</div>
          <div class="fc_line" @click="storeList(row,'02')">添加</div> -->
          <div class="fc_line" @click="$refs['storeDia'].open(row, '01')">
            {{ row[prop] }}
          </div>
          <div class="fc_line" @click="$refs['storeDia'].open(row, '02')">
            添加
          </div>
        </div>
        <!--显示真实KA名称 -->
        <div v-else-if="prop === 'KAmerchantname'">
          <el-switch
            v-model="row.isDisplayRealName"
            @change="ErpMethodsSwitch($event, row)"
          >
          </el-switch>
        </div>
        <!-- 操作 -->
        <div v-else-if="prop === 'action'">
          <div
            v-show="row.companyIsEnable !== '01'"
            class="fc"
            style="cursor: pointer"
            @click="quotationAction(row)"
          >
            启用
          </div>
          <div
            v-show="row.companyIsEnable === '01'"
            style="color: #f80000; cursor: pointer"
            @click="quotationAction(row)"
          >
            禁用
          </div>
        </div>
        <!-- 其他 -->
        <div v-else>
          {{ row[prop] }}
        </div>
      </template>
    </DialogTableSearch>
    <StoreDia ref="storeDia" @confirm="recoveryNumSearch()" />
    <!-- 显示真实KA配置弹窗 -->
    <el-dialog
      title="操作提示"
      :visible.sync="uniformVisible"
      :close-on-click-modal="false"
      width="460px"
      @closed="unityDialogClosed"
    >
      <div style="font-size: 13px; color: red">
        <span v-if="isUniformly">说明: 启用后，该回收商显示真实KA名称</span>
        <span v-else>说明: 关闭后，该回收商报价页面不在显示真实KA名称</span>
      </div>
      <div class="centercontent" v-if="isUniformly">
        是否确认开启所选<span style="color: #0981ff"
          >{{ unitsNumber }}个回收商</span
        >在该矩阵显示真实KA名称?
      </div>
      <div class="centercontent" v-else>
        是否确认关闭<span style="color: #0981ff">{{
          mertcahantData.merchantName
        }}</span
        >在该矩阵显示真实KA名称?
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input
          v-model="contactCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            uniformVisible = false;
            contactCommand = '';
          "
          >取消</el-button
        >
        <el-button type="primary" @click.native="uniSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 矩陣报价回收商门店列表 -->
    <DialogTableSearch
      v-bind="storeListProp"
      @close="
        storeListProp.show = false;
        storeListProp.isResetForm = false;
      "
      @handleConfirm="(v) => storeListSearch(v)"
      @handleSelectionChange="handleSelectionChange"
      @config="storeListConfig"
    >
      <template v-slot="{ row, prop }">
        <!-- 回收商数量 -->
        <div v-if="prop === 'inquiryMerchantName'">
          <el-tooltip
            class="item"
            effect="dark"
            :content="row[prop]"
            placement="bottom-start"
          >
            <div class="ycyc2">
              {{ row[prop] }}
            </div>
          </el-tooltip>
        </div>
        <!--  -->
        <div v-else-if="prop === 'selection'"></div>
        <div v-else>
          {{ row[prop] }}
        </div>
      </template>
      <template v-slot:action>
        <div style="width: 100%; overflow: hidden; margin-bottom: 10px">
          <div style="float: right">
            <el-button
              @click="storeExcle"
              :loading="storeListProp.isExcelLoading"
              type="success"
              size="mini"
              icon="el-icon-download"
              >导出Excel
            </el-button>
          </div>
        </div>
      </template>
    </DialogTableSearch>

    <!-- 启用、禁用提示 -->
    <DialogAction
      v-bind="quotationTipProp"
      @close="quotationTipProp.show = false"
      @config="quotationConfig"
      @codeChange="(v) => (quotationTipProp.code = v)"
    />
    <el-dialog
      class="add-err-dialog"
      title='添加失败'
      :visible.sync="addErrShow"
      :close-on-click-modal="false"
      width="500px"
    >
      <div style="margin-bottom: 10px">已过滤<span style="color:#FF687B;">{{cooperationIds.length}}个</span>已在合作中的门店商户，可继续操作将剩余商户建立合作</div>
      <div class="err-info">
        <p style="margin-bottom: 10px">以下商户已在合作中:</p>
        <span style="color:#FF687B;">{{ cooperationName}}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addErrShow=false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GlobalInfoBar from "@/components/global/components/GlobalInfoBar.vue";
import GlobalChunk from "@/components/global/components/GlobalChunk.vue";
import {
  addRecoveryProps,
  createMatrixCode,
  createMatrixCodeProp,
  createMatrixForm,
  createMatrixFormList,
  createMatrixRules,
  listColumns,
  listFormList,
  quotationTipProp,
  recoveryNumProps,
  storeListProp,
} from "@/views/matrix/initState";
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import _api from "@/utils/request";
import {
  getIndex,
  getMeta,
  guideExcelAction,
  openAction,
  pageSize,
  setValue,
} from "@/utils/common";
import Pagination from "@/components/common/Pagination.vue";
import DialogTableSearch from "@/components/common/DialogTableSearch.vue";
import DialogAction from "@/components/common/DialogAction.vue";
import SettingDia from "./dialog/SettingDia.vue";
import TagDia from "./dialog/TagDia.vue";
import StoreDia from "./dialog/StoreDia.vue";

export default {
  name: "config",
  components: {
    DialogAction,
    DialogTableSearch,
    Pagination,
    GlobalForm,
    GlobalChunk,
    GlobalInfoBar,
    SettingDia,
    TagDia,
    StoreDia,
  },
  data() {
    const { matrixId, matrixName } = this.$route.query;
    return {
      isUniformly: false,
      unitsNumber: 0,
      mertcahantData: [],
      contactCommand: "",
      uniformVisible: false,
      isbatch:false,
      merchantselectData: [],
      // 搜索from
      formItemList: listFormList,
      entrepotList: [],
      // 表数据
      listData: [],
      initData: {
        matrixId: matrixName,
      },
      addStoreBtn: true,
      tableChooseLength: "",
      moreChooseStore: false,
      // 分页数据
      meta: {
        total: 0,
        pageSize,
        pageNum: 1,
      },
      // 是否加载
      loading: true,
      // 列表列
      listColumns,
      // 搜索参
      params: {
        pageSize,
        pageNum: 1,
        matrixId,
      },
      moreCurrentData: [],
      // 谷歌验证码
      createMatrixCodeProp,
      // 矩陣新建
      isCreateShow: false,
      // // 门店商下拉
      // companyList: [],
      // // 回收商下拉
      // merchantList: [],
      // 外部商户
      outChannelList: [],
      // 创建矩阵form值
      createMatrixForm,
      // 创建矩到form列表
      createMatrixFormList,
      // 规则验证
      createMatrixRules,
      // 当前数据
      currentData: {},
      // 矩阵设置
      isSetting: false,
      // 取价时间
      settingForm: {
        priceTime: null,
      },
      // 添加回收商
      isAddRecovery: false,
      // 回收商选的数据，如果用戶点击了取消，需要保证原本确定选的依旧存在
      selectRecoveryData: {},
      // 添加回收商
      addRecoveryForm: {
        // 回收名称
        merchantName: "",
        command: "",
        // 矩阵id
        matrixId: "",
        // 回收商名称
        merchantId: "",
        // 联系人
        contactName: "",
        //仓库id
        warehouseId: "",
        // 联系电话
        contactPhone: "",
        loading: false,
      },
      // 添加回收商弹窗相关数据
      addRecoveryProp: addRecoveryProps,
      // 回收商数量
      recoveryNumProps,
      // 矩阵回收商关联门店列
      storeListProp,
      // 禁用启用报价提示
      quotationTipProp,
      addErrShow: false,
      cooperationIds: [],
      cooperationName: ""
    };
  },
  created() {
    // 下拉请求
    this.getSelectList();
    this.getwarehouseSelectList();
    // 列表请求
    this.getList();
    this.createMatrixRules = {
      ...this.createMatrixRules,
      channelCode: [
        {
          validator: this.channelValidator,
          trigger: "blur",
        },
      ],
    };
  },

  watch: {
    params() {
      this.getList();
    },
    //新建矩阵，外部渠道选择切换时
    "createMatrixForm.isOut"(v) {
      // 控制渠道索引
      const index = this.createMatrixFormList.findIndex(
        (v) => v.key === "channelCode"
      );
      // 控制回收商索引
      const merchantIndex = this.createMatrixFormList.findIndex(
        (v) => v.key === "merchantId"
      );
      // 设置显示
      this.createMatrixFormList[index].isShow = v;
      // 设置显示
      this.createMatrixFormList[merchantIndex].isShow = !v;
      if (v) {
        this.createMatrixRules.merchantId[0].required = false;
      } else {
        this.createMatrixRules.merchantId[0].required = true;
      }
    },
  },

  methods: {
    openAction,
    uniSubmit() {
      let onprams = {
        command: this.contactCommand,
        isDisplayRealName: this.isUniformly,
        matrixId: this.currentData.id,
        merchantId: [],
      };
      if(this.isbatch){
        onprams.merchantId=this.merchantselectData.map(item=>item.merchantId)
      }else{
        onprams.merchantId.push(this.mertcahantData.merchantId)
      }
      console.log(onprams)
      _api.displayRealname(onprams).then((res) => {
        this.uniformVisible = false;
        this.isbatch=false;
        this.getRecoveryNumList();
      });
    },
    unityDialogClosed() {
      this.contactCommand = ''
      this.getRecoveryNumList();
    },
    ErpMethodsSwitch(e, row) {
      console.log(row);
      this.isbatch=false;
      this.isUniformly = row.isDisplayRealName;
      this.mertcahantData = row;
      this.uniformVisible = true;
      this.unitsNumber = 1;
    },
    allTrunon() {
      this.isbatch=true;
      this.isUniformly = true;
      this.unitsNumber = this.merchantselectData.length;
      this.uniformVisible = true;
    },
    //获取仓库
    getwarehouseSelectList() {
      _api.getwarehouseSelectList().then((res) => {
        this.entrepotList = res.data;
      });
    },
    // 门店列表导表操作
    storeExcle() {
      this.storeListProp.isExcelLoading = true;
      _api
        .excelStoreInquiryMerchantList(this.storeListProp.params)
        .then((r) => {
          guideExcelAction(r, "报价回收商门店列表", () => {
            this.storeListProp.isExcelLoading = false;
          });
        });
    },
    storeListHref(row) {
      this.$router.resolve({
        path: "/StoreShop/index",
        query: { matrixId: row.id },
      });
    },
    // 设置数组项
    setArrItem(arr, label = "name", value = "id") {
      return arr.map((v) => {
        return {
          label: v[label],
          value: v[value],
        };
      });
    },
    // 总监区域下拉
    getInspectorAreaList(companyId) {
      _api.inspectorAreaSelect({ companyId }).then((r) => {
        r.data = Array.isArray(r.data) ? r.data : [];
        const index = this.storeListProp.formItemList.findIndex(
          (v) => v.key === "staffId"
        );

        this.storeListProp.formItemList[index].option = r.data.map((v) => {
          return {
            value: v.staffId,
            label:
              (v.staffName ? v.staffName : "") +
              (v.areaName ? "-" + v.areaName : ""),
          };
        });
      });
    },
    // 下拉，isFilterAdd新建商户，需要拿到没关联矩阵的商户，所以动态传参，不传代表初次进入，传代表新建时，重新拿门店和回收商下拉
    getSelectList(isFilterAdd = false) {
      if (!isFilterAdd) {
        // 矩阵下拉
        _api.getSelectMatrixList().then((r) => {
          const data = r.data || [];
          this.formItemList[0].option = this.setArrItem(data);
        });

        // 包含门店商户
        _api.getSelectList({ onlinePay: true }).then((r) => {
          const data = r.data || [];
          const index = this.formItemList.findIndex(
            (v) => v.key === "innerCompanyId"
          );
          this.formItemList[index].option = this.setArrItem(data);
        });
        // 包含回收商请求
        _api
          .listSimpleMerchantView({ isNice: "00", pageNum: 1, pageSize: 9999 })
          .then((r) => {
            const data = r.data || [];
            const index = this.formItemList.findIndex(
              (v) => v.key === "innerMerchantId"
            );
            const options = this.setArrItem(data, "merchantName", "merchantId");
            this.formItemList[index].option = options;
            this.formItemList[index + 1].option = options;
          });
      }

      // 门店商下拉
      _api.getMatrixCompanySelectList(isFilterAdd).then((r) => {
        const data = r.data || [];
        const companyList = this.setArrItem(data);
        if (isFilterAdd) {
          const index = this.createMatrixFormList.findIndex(
            (v) => v.key === "companyId"
          );
          this.createMatrixFormList[index].options = companyList;
        } else {
          this.formItemList[1].option = companyList;
        }
      });

      // 回收商下拉
      _api.getMatrixMerchantSelectList(isFilterAdd).then((r) => {
        const data = r.data || [];
        const merchantList = this.setArrItem(
          data,
          "merchantName",
          "merchantId"
        );
        if (isFilterAdd) {
          const index = this.createMatrixFormList.findIndex(
            (v) => v.key === "merchantId"
          );
          this.createMatrixFormList[index].options = merchantList;
        } else {
          this.formItemList[2].option = merchantList;
        }
      });

      if (isFilterAdd) {
        // 外部渠道
        _api.getOutChannelSelectList().then((r) => {
          const data = r.data || [];
          const index = this.createMatrixFormList.findIndex(
            (v) => v.key === "channelCode"
          );
          this.outChannelList = this.setArrItem(data, "value", "key");
          this.createMatrixFormList[index].options = this.outChannelList;
        });
      }
    },

    // 获取列表
    getList() {
      this.loading = true;
      _api.matrixList(this.params).then((r) => {
        const data = r.data.records || [];
        const params = this.params;
        this.loading = false;
        // 列
        this.listData = data.map((v, i) => {
          v = setValue(v);
          return {
            ...v,
            index: getIndex(params.pageNum, params.pageSize, i),
            isOut: v.isOut ? (v.isOut === "--" ? "--" : "是") : "否",
          };
        });
        // 分页
        this.meta = getMeta(r.data.total, r.data.current, r.data.size);
      }).finally(() => {
        if (typeof this.params.disuseLoding === 'function') {
          this.params.disuseLoding();
        }
      });
    },

    // 创建矩阵
    createClick() {
      this.isCreateShow = true;
      this.createMatrixForm = JSON.parse(JSON.stringify(createMatrixForm));
      this.getSelectList(true);
    },
    // 批量添加回收商
    handleTableSelect(val) {
      this.moreCurrentData = val.map((item) => {
        return item.id;
      });
      if (val.length) {
        this.addStoreBtn = false;
        this.tableChooseLength = val.length;
      } else {
        this.addStoreBtn = true;
      }
      console.log(val, "val");
    },
    addStore() {
      console.log(this.currentData, "curre");
      this.isAddRecovery = true;
      this.moreChooseStore = true;
    },
    // 搜索确定操作
    handleConfirm(v,cd) {
      const { matrixName, matrixId } = this.$route.query;
      this.params = {
        ...this.params,
        pageNum: 1,
        ...v,
        matrixId: v.matrixId === matrixName ? matrixId : v.matrixId,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.listData = [];
    },
    // 外部渠道验证规则
    channelValidator(rule, value, callback) {
      if (this.createMatrixForm.isOut) {
        if (!value) {
          callback(new Error("请选择外部渠道商"));
        } else {
          callback();
        }
      }
      callback();
    },
    // 提交
    createSubmit() {
      this.$refs["createMatrix"].validate((v) => {
        if (v) {
          this.createMatrixCodeProp = {
            ...this.createMatrixCodeProp,
            show: true,
            code: "",
          };
        } else {
          return false;
        }
      });
    },

    // 谷歌验证码提交
    createMatrixSubmit() {
      if (this.createMatrixCodeProp.loading) {
        return;
      }
      if (!this.createMatrixCodeProp.code) {
        return this.$message.error("请输入谷歌动态码");
      }
      let data = {
        ...this.createMatrixForm,
        command: this.createMatrixCodeProp.code,
      };
      if (data.isOut) {
        data = {
          ...data,
          merchantId: null,
        };
      } else {
        data = {
          ...data,
          channelCode: null,
        };
      }
      this.createMatrixCodeProp = {
        ...this.createMatrixCodeProp,
        loading: true,
      };
      _api
        .matrixAdd(data)
        .then((r) => {
          this.isCreateShow = false;
          this.createMatrixCodeProp = {
            ...this.createMatrixCodeProp,
            show: false,
            loading: false,
          };
          this.$message.success("操作成功");
          this.params = {
            ...this.params,
            pageNum: 1,
          };
        })
        .catch(() => {
          this.createMatrixCodeProp = {
            ...this.createMatrixCodeProp,
            loading: false,
          };
        });
    },

    // 输入限制
    timeValidator(rule, value, callback) {
      if (Number(value) < 60) {
        return callback(new Error("报价时间设置值必须在60秒以上"));
      }
      callback();
    },

    // 矩阵设置
    settingClick(v) {
      this.isSetting = true;
      this.settingForm = {
        ...this.settingForm,
        priceTime: v.getPriceTime || null,
      };
      this.currentData = v;
    },

    // 价格时间变化
    priceInput(v) {
      let value = Number(v);
      if (isNaN(value)) {
        return (this.settingForm.priceTime = 60);
      }
      if (value > 600) {
        return (this.settingForm.priceTime = 600);
      }
    },

    // 矩阵设置提交
    settingSubmit() {
      const getPriceTime = Number(this.settingForm.priceTime);
      this.$refs["settingForm"].validate((v) => {
        if (v) {
          _api
            .matrixPrice({
              matrixId: this.currentData.id,
              getPriceTime,
            })
            .then(() => {
              this.$message.success("操作成功");
              this.isSetting = false;
              this.params = {
                ...this.params,
                pageNum: 1,
              };
            });
        } else {
          return false;
        }
      });
    },

    // 回收商列表请求
    getRecoveryList() {
      this.addRecoveryProp = {
        ...this.addRecoveryProp,
        loading: true,
      };
      _api.ShopTable(this.addRecoveryProp.params).then((r) => {
        const data = r.data || {};
        const tabData = (data.records || []).map((v) => {
          v = setValue(v);
          return v;
        });
        // 分页
        const meta = getMeta(r.data.total, r.data.current, r.data.size);
        // 更新状态
        this.addRecoveryProp = {
          ...this.addRecoveryProp,
          loading: false,
          meta,
          tabData,
        };
      });
    },

    // 添加回收商
    addRecovery(v) {
      console.log(v, "vv");
      this.currentData = v;
      this.isAddRecovery = true;
      this.addRecoveryForm = {};
      this.moreChooseStore = false;
    },

    // 添加回收商点击
    addRecoveryClick() {
      this.addRecoveryProp = {
        ...this.addRecoveryProp,
        show: true,
        params: {
          ...this.addRecoveryProp.params,
          pageNum: 1,
          merchantName: "",
        },
        isResetForm: true,
      };
      this.getRecoveryList();
      this.selectRecoveryData = this.addRecoveryForm;
      this.addRecoveryForm.loading = false;
    },

    // 查询
    addRecoverySearch(v) {
      this.addRecoveryProp = {
        ...this.addRecoveryProp,
        params: {
          ...this.addRecoveryProp.params,
          ...v,
          pageNum: 1,
        },
        tabData: [],
      };
      this.getRecoveryList();
    },
    // 回收商选择项变化
    addRecoveryChange(v) {
      this.selectRecoveryData = {
        ...this.addRecoveryForm,
        merchantId: v.merchantId,
        contactName: v.contactName,
        contactPhone: v.contactPhone,
        merchantName: v.merchantName || "--",
      };
    },

    // 回收商选择确定
    addRecoveryConfig() {
      this.addRecoveryProp.show = false;
      this.addRecoveryForm = this.selectRecoveryData;
    },

    // 添加回收商
    addRecoverySubmit() {
      if (this.addRecoveryForm.loading) {
        return;
      }
      this.addRecoveryForm.loading = true;
      this.$refs["recoveryForm"].validate((v) => {
        if (v) {
          if (this.moreChooseStore == true) {
            const data = {
              matrixIdList: this.moreCurrentData,
              merchantId: this.addRecoveryForm.merchantId,
              warehouseId: this.addRecoveryForm.warehouseId,
            };
            _api
              .matrixMoreSaveUnionMerchant(data)
              .then((r) => {
                if(r.data && r.data.repeatMiddleMatrixList && r.data.repeatMiddleMatrixList.length){
                  this.cooperationIds =  r.data.repeatMiddleMatrixList.map(item => item.id);
                  this.cooperationName =  r.data.repeatMiddleMatrixList.map(item => item.name).join('、');
                  const matchedItems = this.listData.filter(item => this.cooperationIds.includes(item.id));
                  console.log(matchedItems,'matchedItemsmatchedItemsmatchedItemsmatchedItems')
                  this.toggleSelection(matchedItems)
                  this.addErrShow = true
                }else{
                  this.$message.success("操作成功");
                  this.isAddRecovery = false;
                  this.params = {
                    ...this.params,
                    pageNum: 1,
                  };
                  this.addRecoveryForm.merchantName = "";
                  this.addRecoveryProp = {
                    ...this.addRecoveryProp,
                    isResetForm: false,
                  };
                }
                this.addRecoveryForm.loading = false;
              })
              .catch(() => {
                this.addRecoveryForm.loading = false;
              });
          } else {
            const data = {
              command: this.addRecoveryForm.command,
              matrixId: this.currentData.id,
              merchantId: this.addRecoveryForm.merchantId,
              warehouseId: this.addRecoveryForm.warehouseId,
            };
            _api
              .matrixSaveUnionMerchant(data)
              .then((r) => {
                this.$message.success("操作成功");
                this.isAddRecovery = false;
                this.params = {
                  ...this.params,
                  pageNum: 1,
                };
                this.addRecoveryForm.merchantName = "";
                this.addRecoveryProp = {
                  ...this.addRecoveryProp,
                  isResetForm: false,
                };
                this.addRecoveryForm.loading = false;
              })
              .catch(() => {
                this.addRecoveryForm.loading = false;
              });
          }
          return;
        }
        this.addRecoveryForm.loading = false;
        return false;
      });
    },

    // 回收商查看
    recoveryNum(v) {
      this.currentData = v;
      console.log(this.currentData);
      this.recoveryNumProps = {
        ...this.recoveryNumProps,
        params: {
          ...this.recoveryNumProps.params,
          companyId: v.companyId,
          merchantName: null,
          pageNum: 1,
          companyIsEnable: null,
        },
        show: true,
        isResetForm: true,
      };
      if(this.currentData.isOut == '是'){
        this.recoveryNumProps.tableColumns=this.recoveryNumProps.tableColumns.filter(item=>item.prop!='KAmerchantname')
      }else{
        if(this.recoveryNumProps.tableColumns.filter(item=>item.prop=='KAmerchantname').length){
          console.log("不用处理")
        }else{
          this.recoveryNumProps.tableColumns.splice(this.recoveryNumProps.tableColumns.length-1,1, {
            prop: "KAmerchantname",
            label: "显示真实KA名称",
        },
        {
            prop: "action",
            label: "操作",
        })
        }
       
      }
      this.getRecoveryNumList();
    },
    amendWarsh(row) {
      row.isupdata = !row.isupdata;
    },
    saveWarsh(row) {
      // row.isupdata = !row.isupdata;
      _api
        .updateMerchantWarehouse({
          matrixId: row.matrixId,
          merchantId: row.merchantId,
          warehouseId: row.warehouseId,
        })
        .then((res) => {
          this.getRecoveryNumList();
        });
    },

    // 回收商数量rq
    getRecoveryNumList() {
      this.recoveryNumProps = {
        ...this.recoveryNumProps,
        loading: true,
      };
      const { params } = this.recoveryNumProps;
      _api.unionMerchantList(params).then((r) => {
        // 分页
        const meta = getMeta(r.data.total, r.data.current, r.data.size);
        const data = r.data || {};
        let records = data.records || [];
        this.recoveryNumProps = {
          ...this.recoveryNumProps,
          loading: false,
          meta,
          tabData: records.map((v, i) => {
            v = setValue(v);
            return {
              ...v,
              isupdata: true,
              index: getIndex(params.pageNum, params.pageSize, i),
            };
          }),
        };
      });
    },
    // 回收商搜索
    recoveryNumSearch(v) {
      this.recoveryNumProps = {
        ...this.recoveryNumProps,
        tableData: [],
        params: {
          ...this.recoveryNumProps.params,
          pageNum: 1,
          ...v,
        },
      };
      this.getRecoveryNumList();
    },

    // 回收商禁用启用
    quotationAction(v) {
      const isType = v.companyIsEnable === "01";
      this.quotationTipProp = {
        ...this.quotationTipProp,
        show: true,
        code: "",
        currentData: v,
        width: "30%",
        isType,
        title: !isType ? "启用提示" : "禁用提示",
        tipText: !isType
          ? "是否确认启用该商家报价？"
          : "是否确认禁用该商户参与矩阵报价",
      };
    },

    // 回收商禁用启用确定
    quotationConfig() {
      // closeQuote 关闭报价
      // saveUnionMerchant 开启报价
      let rq;
      const { isType, code, currentData } = this.quotationTipProp;
      if (!code) {
        return this.$message.error("请输入动态口令");
      }
      let params = {
        command: code,
        companyId: currentData.companyId,
        merchantId: currentData.merchantId,
      };

      // 启用
      if (!isType) {
        console.log(this.currentData);
        params = {
          command: code,
          merchantId: currentData.merchantId,
          matrixId: this.currentData.id,
          warehouseId: currentData.warehouseId,
        };
        rq = _api.matrixSaveUnionMerchant;
      } else {
        rq = _api.closeQuote;
      }
      rq(params).then(() => {
        this.$message.success("操作成功");
        this.quotationTipProp = {
          ...this.quotationTipProp,
          show: false,
        };
        // 重新更新数据
        this.getRecoveryNumList();
      });
    },

    // 矩阵回收商关联的门店列表rq
    getStoreList() {
      this.storeListProp = {
        ...this.storeListProp,
        loading: true,
      };
      const { params } = this.storeListProp;
      _api.storeInquiryMerchantList(params).then((r) => {
        let data = (r.data || []).map((v, i) => {
          v = setValue(v);
          return {
            ...v,
            index: i + 1,
          };
        });
        this.storeListProp = {
          ...this.storeListProp,
          loading: false,
          tabData: data,
          allTabData: data,
        };
      });
    },

    // 矩阵回收商关联门店搜索
    storeListSearch(v) {
      this.storeListProp.params = {
        ...this.storeListProp.params,
        ...v,
      };
      const allTabData = this.storeListProp.allTabData;
      // 都不存在
      if (!v.staffId && !v.name && !v.merchantNum && v.merchantNum !== 0) {
        return (this.storeListProp = {
          ...this.storeListProp,
          tabData: allTabData,
        });
      }

      // 只搜索总监
      if (v.staffId && !v.name && !v.merchantNum && v.merchantNum !== 0) {
        return (this.storeListProp = {
          ...this.storeListProp,
          tabData: allTabData.filter((item) => item.inspectorId == v.staffId),
        });
      }

      // 只搜索名称
      if (!v.staffId && v.name && !v.merchantNum && v.merchantNum !== 0) {
        return (this.storeListProp = {
          ...this.storeListProp,
          tabData: allTabData.filter(
            (item) => item.storeName.indexOf(v.name) >= 0
          ),
        });
      }
      //只搜索回收商数量
      if (!v.staffId && !v.name && (v.merchantNum || v.merchantNum === 0)) {
        return (this.storeListProp = {
          ...this.storeListProp,
          tabData: allTabData.filter(
            (item) =>
              parseInt(item.inquiryMerchantNum) === parseInt(v.merchantNum)
          ),
        });
      }

      // 都搜索
      if (v.staffId && v.name && (v.merchantNum || v.merchantNum === 0)) {
        return (this.storeListProp = {
          ...this.storeListProp,
          tabData: allTabData.filter(
            (item) =>
              v.staffId == item.inspectorId &&
              item.name.indexOf(v.name) >= 0 &&
              parseInt(item.inquiryMerchantNum) === parseInt(v.merchantNum)
          ),
        });
      }
    },
    // 矩阵回收商关联的门店列表
    storeList(row, enable) {
      this.storeListProp = {
        ...this.storeListProp,
        show: true,
        isResetForm: true,
        params: {
          enable,
          merchantId: row.merchantId,
          matrixId: this.currentData.id,
        },
        selectData: [],
        allSelectData: [],
        currentData: row,
      };
      if (enable === "01") {
        this.storeListProp = {
          ...this.storeListProp,
          title: "移除门店",
          configText: "确定移除",
        };
      } else {
        this.storeListProp = {
          ...this.storeListProp,
          title: "添加门店",
          configText: "确定添加",
        };
      }
      // 区域/总监
      this.getInspectorAreaList(this.currentData.companyId);
      // 门店list
      this.getStoreList();
    },
    merchantSelectionChange(e) {
      console.log(e);
      this.merchantselectData = e;
    },
    // 矩陣回收商门店选择项添加或者删除操作
    handleSelectionChange(val) {
      this.storeListProp = {
        ...this.storeListProp,
        // 选择的所有数据
        allSelectData: val,
        selectData: val.map((v) => v.id),
      };
    },
    // 矩阵回收商门店添加或者移除确定操作
    storeListConfig() {
      const storeIds = this.storeListProp.selectData;
      const enable = this.storeListProp.params.enable;
      const data = {
        storeIds,
        matrixId: this.currentData.id,
        merchantId: this.storeListProp.currentData.merchantId,
      };
      if (enable === "01") {
        data.enable = "02";
      } else {
        data.enable = "01";
      }

      _api.doStoreInquiryMerchant(data).then((r) => {
        this.$message.success("操作成功");
        this.storeListProp = {
          ...this.storeListProp,
          show: false,
          allSelectData: [],
          isResetForm: false,
        };
        // 重新请求
        this.recoveryNumSearch();
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.listData.toggleRowSelection(row);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    transform: translateY(1px);
  }

  > h2 {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
}

.fc {
  color: #0981ff;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fc_line {
  color: #0981ff;
  text-decoration: underline;
  cursor: pointer;
}
.fc_line.danger {
  color: #f93636;
}

.f-line {
  text-decoration: underline;
}

.tip {
  color: #f93636;
  font-size: 12px;
  margin-bottom: 20px;
}

.create-form {
  /deep/ .el-radio {
    margin-bottom: 20px;
  }
}

.time-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #666666;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.required::before {
  content: "*";
  color: #ff687b;
  margin-right: 4px;
  font-size: 14px;
}

.add-recover-info {
  margin-top: 20px;
  display: flex;
  padding-left: 19px;
  color: #111;
  font-size: 14px;
  width: 90%;
  justify-content: space-between;

  > p {
    margin-right: 20px;

    &:last-of-type {
      flex: 2;
    }

    &:first-of-type {
      flex: 3;
    }
  }
}

.ycyc2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.centercontent {
  font-size: 16px;
  color: #666666;
  text-align: center;
  margin: 20px 0;
  // height: 80px;
  // line-height: 80px;
}
.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}
.err-info{
  background: #f5f6fa;
  padding: 20px;
  border-radius: 10px;
  max-height: 400px;
  overflow-y: scroll;
}
</style>
